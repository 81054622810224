<template>
  <CustomDialog
    :title="title"
    v-model="open"
    :has-footer="false"
    max-width="1024"
  >
    <template v-slot:content>
      <v-card flat>
        <v-expansion-panels flat>
          <v-expansion-panel
            class="border-1"
            v-for="(milestone, i) in milestones"
            :key="milestone.id"
          >
            <v-expansion-panel-header class="fw-500">
              Milestone {{ i + 1 }}:
              <strong class="ml-1">{{ milestone.title | ucwords }}</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list dense>
                <v-list-item>
                  <v-list-item-title class="primary--text fw-900 fs-15">
                    <strong>Milestone Tasks</strong>
                  </v-list-item-title>
                  <v-list-item-action-text class="primary--text fw-900 fs-15">
                    <strong>Assigned</strong>
                  </v-list-item-action-text>
                </v-list-item>
                <v-list-item
                  v-for="task in milestone.simpletasks"
                  :key="task.id"
                >
                  <v-list-item-content>
                    <v-list-item-title class="primary--text">
                      {{ task.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      :inner-html.prop="task.description | truncate"
                    >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action-text class="fw-700">
                    {{ task.role ? task.role.name : 'Any' }}
                  </v-list-item-action-text>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import request from '@/services/axios_instance'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
export default {
  name: 'ViewMilestoneTemplate',
  components: { CustomDialog },
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object }
  },

  data: () => ({
    open: false,
    milestones: null
  }),
  computed: {
    title() {
      return this.item ? 'Milestone Template : ' + this.item.name : ''
    }
  },
  watch: {
    value(val) {
      this.open = val
    },
    open(val) {
      this.$emit('input', val)
    },
    item: {
      handler: function (val) {
        if (val && val.replica_type.includes('Milestone')) {
          this.fetchMilestoneAndTasks(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    closeDialog() {
      this.open = false
    },
    fetchMilestoneAndTasks(template) {
      this.fetching = true
      request
        .get(`api/template/${template.id}/milestones-tasks`)
        .then(({ data }) => {
          this.milestones = data
        })
        .finally(() => (this.fetching = false))
    }
  }
}
</script>

<style></style>
