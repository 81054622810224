<template>
  <v-card v-bind="$attrs" class="rounded-10" outlined flat>
    <v-card-title class="primary--text fw-700 fw-18">
      {{ item.name | ucwords }}
    </v-card-title>
    <v-card-subtitle class="fw-700 fs-14 secondary--text">
      <v-icon title="Creator" color="primary">mdi-account</v-icon>
      {{ item.company.name }}
    </v-card-subtitle>
    <v-card-actions
      :class="item.has_copied ? 'justify-space-between' : 'justify-end'"
      class="d-flex align-center"
    >
      <strong v-if="item.has_copied" class="error--text fw-700">
        Already copied!
      </strong>
      <div class="d-flex align-center justify-end">
        <v-btn
          @click="viewTemplate"
          small
          class="text-none px-5 ma-1"
          color="primary"
          depressed
        >
          View
        </v-btn>
        <v-btn
          @click="importTemplate"
          small
          class="text-none px-5 ma-1"
          color="primary"
          depressed
        >
          Import <span class="ml-1" v-if="item.has_copied">Again</span>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true }
  },
  methods: {
    viewTemplate() {
      this.$emit('view', this.item)
    },
    importTemplate() {
      this.$emit('import', this.item)
    }
  }
}
</script>

<style></style>
