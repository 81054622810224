<template>
  <v-card min-height="100vh" class="rounded-10" flat outlined>
    <v-card-title class="d-flex flex-column align-center justify-start">
      <v-row
        dense
        class="fullwidth mb-1"
        align="center"
        justify="space-between"
      >
        <v-col grow>
          <strong>Import Templates</strong>
        </v-col>
        <v-btn @click="$router.go(-1)" class="text-none" color="primary">
          <v-icon left>mdi-chevron-left</v-icon> Back
        </v-btn>
      </v-row>
      <v-row dense class="fullwidth" align="center" justify="start">
        <v-tabs v-model="tab">
          <v-tab href="#milestones" class="text-none">Milestones</v-tab>
          <v-tab href="#reports" class="text-none">Reports</v-tab>
          <v-tab href="#requests" class="text-none">Requests</v-tab>
        </v-tabs>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row
        align="stretch"
        justify="start"
        dense
        v-if="!loading_items && items.length"
      >
        <v-col cols="12" md="4" sm="12" v-for="item in items" :key="item.id">
          <EachMilestoneTemplate
            v-if="tab === 'milestones'"
            class="fill-height"
            :item="item"
            @view="viewTemplate('milestones', item)"
            @import="handleImport('milestones', item)"
          ></EachMilestoneTemplate>
          <EachClientRequestTemplate
            v-else-if="tab === 'requests'"
            :item="item"
            class="fill-height"
            @view="viewTemplate('requests', item)"
            @import="handleImport('requests', item)"
          ></EachClientRequestTemplate>
          <EachReportTemplate
            v-else-if="tab === 'reports'"
            :item="item"
            @view="viewTemplate('reports', item)"
            @import="handleImport('reports', item)"
            class="fill-height"
          ></EachReportTemplate>
        </v-col>
      </v-row>
      <PageLoader v-if="loading_items" min-height="100vh"></PageLoader>
      <Empty
        headline="No templates found!"
        min-height="100vh"
        v-if="!loading_items && !items.length"
      ></Empty>
    </v-card-text>

    <ViewMilestoneTemplate
      v-model="view_milestone_template_dialog"
      :item="activeItem"
    ></ViewMilestoneTemplate>
    <ViewReportTemplate
      :item="activeItem"
      v-model="view_report_template_dialog"
    ></ViewReportTemplate>
    <ViewRequestTemplate
      :item="activeItem"
      v-model="view_request_template_dialog"
    ></ViewRequestTemplate>
  </v-card>
</template>

<script>
import { fetch_services } from '@/services/fetch/fetch_services'
import EachMilestoneTemplate from '../components/EachMilestoneTemplate.vue'
import EachClientRequestTemplate from '../components/EachClientRequestTemplate.vue'
import EachReportTemplate from '../components/EachReportTemplate.vue'
import request from '@/services/axios_instance'
import ViewMilestoneTemplate from '../components/ViewMilestoneTemplate.vue'
import ViewReportTemplate from '../components/ViewReportTemplate.vue'
import ViewRequestTemplate from '../components/ViewRequestTemplate.vue'
export default {
  components: {
    EachMilestoneTemplate,
    EachClientRequestTemplate,
    EachReportTemplate,
    ViewMilestoneTemplate,
    ViewReportTemplate,
    ViewRequestTemplate
  },
  mixins: [fetch_services],
  data: () => ({
    tab: 'milestones',
    view_milestone_template_dialog: false,
    view_report_template_dialog: false,
    view_request_template_dialog: false,
    importing: false
  }),
  watch: {
    '$route.params.tab': {
      handler: function (val) {
        this.tab = val ? val : 'milestones'
      },
      immediate: true
    },
    tab: {
      handler: function (val) {
        this.loadItems(`api/template/public/${val}`, true, null, null, null)
      },
      immediate: true
    }
  },
  methods: {
    viewTemplate(type, item) {
      this.activeItem = item
      if (type === 'milestones') {
        this.view_milestone_template_dialog = true
        this.view_report_template_dialog = false
        this.view_request_template_dialog = false
      } else if (type === 'reports') {
        this.view_milestone_template_dialog = false
        this.view_report_template_dialog = true
        this.view_request_template_dialog = false
      } else if (type === 'requests') {
        this.view_milestone_template_dialog = false
        this.view_report_template_dialog = false
        this.view_request_template_dialog = true
      }
    },
    handleImport(type, item) {
      if (type === 'milestones') this.handleImportMilestone(item)
      else if (type === 'reports') this.handleImportReport(item)
      else if (type === 'requests') this.handleImportRequest(item)
    },
    handleImportReport(item) {
      this.appConfirmation(
        `Import template: ${item.name}?`,
        () => {
          this.importing = true
          request
            .post(`api/template/import/reports`, { id: item.id })
            .then(({ data }) => {
              this.appSnackbar(`Report template successfuly imported.`)
              let index = this.items.findIndex((i) => i.id == item.id)
              if (~index) {
                this.items[index].has_copied = true
              }
            })
            .finally(() => (this.importing = false))
        },
        null,
        'Yes! Import this template'
      )
    },
    handleImportRequest(item) {
      this.appConfirmation(
        `Import template: ${item.name}?`,
        () => {
          this.importing = true
          request
            .post(`api/template/import/requests`, { id: item.id })
            .then(({ data }) => {
              this.appSnackbar(`Requests template successfuly imported.`)
              let index = this.items.findIndex((i) => i.id == item.id)
              if (~index) {
                this.items[index].has_copied = true
              }
            })
            .finally(() => (this.importing = false))
        },
        null,
        'Yes! Import this template'
      )
    },
    handleImportMilestone(item) {
      this.appConfirmation(
        `Import template: ${item.name}?`,
        () => {
          this.importing = true
          this.appSnackbar(
            `System is importing template. 
            This may take a while to complete. 
            We will let you know once it's done. 
            You can safely navigate to other page while we process the importation.`
          )
          request
            .post(`api/template/import/milestones`, { id: item.id })
            .then(({ data }) => {
              this.appSnackbar(
                `System is importing template. 
                This may take a while to complete. 
                We will let you know once it's done. 
                You can safely navigate to other page while we process the importation.`
              )
              let index = this.items.findIndex((i) => i.id == item.id)
              if (~index) {
                this.items[index].has_copied = true
              }
            })
            .finally(() => (this.importing = false))
        },
        null,
        'Yes! Import this template'
      )
    }
  }
}
</script>

<style></style>
